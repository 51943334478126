window.addEventListener('customer.enquiry', (e) => {
    let store = e.detail.store;

    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
        HttpHost: 'asia.adform.net',
        pm: 2188541,
        divider: encodeURIComponent('|'),
        pagename: encodeURIComponent('Inquiry Form Submitted'),
        order : {
            sv5: store,
        }
    });
});

window.addEventListener('cart.checkout', (e) => {
    let { cart, order } = e.detail;

    // adform track
    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
        HttpHost: 'asia.adform.net',
        pm: 2188541,
        divider: encodeURIComponent('|'),
        pagename: encodeURIComponent('Transaction complete'),
        order : {
            sales: `${ parseFloat(_.get(order, 'amount_billed', 0)).toFixed(2) }`,
            orderid: `${ order.id }`,
            itms: _.chain(cart)
                .get('items', [])
                .map((item, index) => {
                    return {
                        categoryname: `${ _.chain(item)
                            .get('product.categories', [])
                            .first()
                            .get('fullname')
                            .value() }`,
                        productid: `${ _.get(item, 'price.code') }`,
                        productname: `${ _.get(item, 'price.description') }`,
                        productsales: `${ _.chain(item)
                            .get('item_price')
                            .value() }`,
                        quantity: `${ _.get(item, 'quantity') }`,
                        step: 3,
                    }
                }).value(),
        }
    });
    window.initAdform ? window.initAdform() : null;
})

window.addEventListener('cart.init', (e) => {
    let { cart } = e.detail;

    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
        HttpHost: 'asia.adform.net',
        pm: 2188541,
        divider: encodeURIComponent('|'),
        pagename: encodeURIComponent('Basket'),
        order : {
            sales: `${cart.summary.amount_billed}`,
            itms: _.chain(cart)
                .get('items', [])
                .map((item, index) => {
                    return {
                        categoryname: `${ _.chain(item)
                            .get('product.categories', [])
                            .first()
                            .get('fullname')
                            .value() }`,
                        productid: `${ _.get(item, 'price.code') }`,
                        productname: `${ _.get(item, 'price.description') }`,
                        productsales: `${ _.get(item, 'item_price') }`,
                        quantity: `${ _.get(item, 'attributes.quantity') }`,
                        step: 2,
                    }
                }).value(),
        }
    });
    window.initAdform ? window.initAdform() : null;

})

window.addEventListener('category.change', (e) => {
    let { category } = e.detail;

    let fullname = _.get(category, 'fullname');
    let nameParts = _.split(fullname, ' › ');
    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
        HttpHost: 'asia.adform.net',
        pm: 2188541,
        divider: encodeURIComponent('|'),
        pagename: encodeURIComponent('Category'),
        order : {
            sv6: nameParts[0],
            sv7: nameParts.length > 1 ? fullname : null,
        }
    });
    window.initAdform ? window.initAdform() : null;
});

window.addEventListener('product.change', (e) => {
    let { product, category } = e.detail;

    let fullname = _.get(category, 'fullname');
    let nameParts = _.split(fullname, ' › ');

    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
        HttpHost: 'asia.adform.net',
        pm: 2188541,
        divider: encodeURIComponent('|'),
        pagename: encodeURIComponent('Product'),
        order : {
            sv6: nameParts[0],
            sv7: nameParts.length > 1 ? fullname : null,
            itms: [{
                categoryname: fullname,
                productname: _.get(product, 'name', ''),
                productid: _.get(product, 'id', ''),
                productsales: _.get(product, 'item_price', 0),
                step: 1,
            }]
        }
    });
    window.initAdform ? window.initAdform() : null;
})
